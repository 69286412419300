
@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src: url('./assets/fonts/Gilroy-Thin.otf') format('otf');
}

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('./assets/fonts/Gilroy-Regular.otf') format('otf');
}

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url('./assets/fonts/Gilroy-Medium.otf') format('otf');
}

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url('./assets/fonts/Gilroy-Semibold.otf') format('otf');
}

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('./assets/fonts/Gilroy-Bold.otf') format('otf');
}

